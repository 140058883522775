export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = ['Well done!', 'Woohoo!', 'Take that!']
export const GAME_COPIED_MESSAGE = 'Game copied'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Not enough letters'
export const WORD_NOT_FOUND_MESSAGE = 'Word not found'
export const HARD_MODE_ALERT_MESSAGE = 'Why make it harder??'
export const HARD_MODE_DESCRIPTION = 'Same old thing'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'For the colorblind'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `The word was.... ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `You need to use ${guess} in position ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Pay attention.. you need to use ${letter}`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Delete'
export const STATISTICS_TITLE = 'Statistics'
export const GUESS_DISTRIBUTION_TEXT = 'Guess distribution'
export const NEW_WORD_TEXT = 'New word in'
export const SHARE_TEXT = 'Share'
export const TOTAL_TRIES_TEXT = 'Total tries'
export const SUCCESS_RATE_TEXT = 'Success rate'
export const CURRENT_STREAK_TEXT = 'Current streak'
export const BEST_STREAK_TEXT = 'Best streak'
